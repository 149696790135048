@import "../../../scss/variables";
@import "../../../scss/functions";

.footer {
    padding: rem(80) 0;
    border-top: 1px solid $outlined-grey;

    @media (max-width: 1199px) {
        padding: rem(40) 0;
    }

    &__text {
        font-size: rem(14);
        line-height: 130%;
        font-weight: 400;
        color: $grey-text;
        max-width: rem(312);
        margin-top: rem(-72);

        @media (max-width: 1199px) {
            margin-top: 0;
            max-width: none;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 150px;

        @media (max-width: 1199px) {
            margin-bottom: rem(35);
            grid-template-columns: repeat(2, 1fr);
            column-gap: 40px;
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 35px;
        }

        .info {

            @media (max-width: 1199px) {
                grid-column: 1/2;
                grid-row: 1/2;
            }


            &__logo {
                margin-bottom: rem(17);

                @media (max-width: 1199px) {
                    width: rem(129);
                    margin-bottom: rem(12);

                    img {
                        width: 100%;
                    }
                }


            }

            &__title {
                font-size: rem(16);
                line-height: 130%;
                font-weight: 500;
                text-transform: uppercase;

                @media (max-width: 1199px) {
                    font-size: rem(14);
                }
            }
        }

        .menu {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            max-height: rem(126);

            @media (max-width: 1199px) {
                grid-column: 1/2;
                grid-row: 2/3;
                max-height: rem(88);
                align-self: flex-end;
                align-content: flex-start;
            }

            @media (max-width: 767px) {
                grid-column: 1/2;
                grid-row: 2/3;
            }

            &__item {
                width: 50%;
                margin-bottom: rem(18);
                font-size: rem(16);
                line-height: rem(18);
                font-weight: 400;
                margin-right: rem(60);

                @media (max-width: 1199px) {
                    font-size: rem(14);
                    line-height: rem(16);
                    width: fit-content;
                }

                &:nth-child(4) {
                    margin-bottom: 0;
                    @media (max-width: 1199px) {
                        margin-bottom: rem(18);
                    }
                }

                @media (max-width: 1199px) {
                    &:nth-child(3n + 3) {
                        margin-bottom: 0;
                    }
                }

                a {
                    transition: color .3s ease-out;
                }

                &:hover a {
                    color: $green;
                }

            }
        }

        .social {
            @media (max-width: 1199px) {
                grid-column: 2/3;
                grid-row: 1/3;
            }

            @media (max-width: 767px) {
                grid-column: 1/2;
                grid-row: 3/4;
            }


            &__item {
                margin-bottom: rem(16);

                &:last-child {
                    margin-bottom: 0;
                }

                svg {
                    margin-right: 8px;
                }

                span {
                    margin-right: 8px;
                    color: $grey-text;
                    font-size: rem(16);
                    line-height: rem(18);
                }

                a {
                    transition: color .3s ease-out;
                }

                a:hover {
                    color: $green;
                }
            }
        }
    }
}