@import "../../../scss/variables";
@import "../../../scss/functions";

.cards {
    &__item {
        display: flex;
        flex-direction: column;
        padding: rem(32);
        border: 1px solid $grey;
        border-radius: rem(15);

        @media (max-width: 1199px) {
            padding: rem(24);
        }
    }

    &__header {
        height: 40px;
        margin-bottom: rem(24);

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &__info {
        margin-bottom: rem(24);

        div {
            padding: rem(8) rem(13);
            background: $outlined-grey;
            border-radius: rem(54);

            img {
                margin-right: rem(6);
                width: rem(20);
            }

            span {
                font-size: rem(18);
                line-height: 100%;
                font-weight: 500;
            }

            & + div {
                margin-left: rem(8);
            }
        }
    }

    &__stat {
        display: grid;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
        padding: rem(24) 0;
        margin-bottom: rem(24);
        border-top: 1px solid $outlined-grey;
        border-bottom: 1px solid $outlined-grey;

        div {
            display: flex;
            flex-direction: column;
        }

        &_name {
            color: #999999;
            font-size: rem(18);
            line-height: rem(21);
            font-weight: 500;
            margin-bottom: rem(4);
        }

        &_value {
            font-size: rem(24);
            line-height: rem(28);
            font-weight: 600;

            span {
                font-size: rem(18);
                line-height: rem(21);
                text-transform: uppercase;
            }
        }
    }

    &__more {
        color: $green;
        font-size: rem(18);
        line-height: 100%;
        font-weight: 500;
        text-transform: uppercase;
        width: fit-content;
        margin-top: auto;

        &:hover svg {
            transform: rotate(405deg);
        }

        svg {
            margin-left: rem(6);
            transition: transform .3s ease-out;
        }

        &:hover {
            color: $green;
        }
    }


}