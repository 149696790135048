@import '../variables';
@import '../functions';


// general modal classes
.modal-content {
    background: $dark-grey;
    border: 2px solid $white-lime;
    border-radius: 20px;

    .closeBtn {
        width: 40px;
        height: 40px;
        background: url("../../assets/images/general/bg-mini.png") no-repeat;
        border-radius: 50%;
        border: 1px solid #F6FFE4C5;
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 1;

        &:hover {
            border: 1px solid $white-lime;
        }

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            fill: #F6FFE4C5;
            width: 10px;
            height: 10px;
        }

        &:hover svg path {
            fill: #f6ffe4;
        }
    }
}



