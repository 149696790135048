@import '../../../scss/functions';
@import '../../../scss/variables';

.indAdv {
    margin-bottom: rem(80);

    @media (max-width: 575px) {
        margin-bottom: rem(82);
    }

    .main__title {
        min-width: rem(524);
        font-size: rem(72);
        line-height: 110%;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: rem(60);

        @media (max-width: 1199px) {
            font-size: rem(52);
            margin-bottom: rem(20);
            min-width: auto;
        }

        @media (max-width: 767px) {
            font-size: rem(36);
        }
    }

    .main__subtitle {
        font-size: rem(72);
        line-height: 110%;
        text-transform: uppercase;
        font-weight: 400;

        @media (max-width: 1199px) {
            font-size: rem(36);
        }
    }

    &__wrapper {
        position: relative;
        display: grid;
        grid-template-columns: minmax(42%, 524px) minmax(53%, 656px);
        gap: 40px 60px;

        @media (max-width: 991px) {
            grid-template-columns: auto 160px;
            gap: 0 10px;
        }

        @media (max-width: 575px) {
            grid-template-columns: 1fr;
            gap: 0;
        }
    }

    .main__title {
        margin-right: 0;
        margin-bottom: 0;
        min-width: auto;

        @media (max-width: 991px) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            margin-bottom: rem(20);
        }

        @media (max-width: 575px) {
            max-width: calc(100% - 180px);
        }
    }

    .main__subtitle {
        @media (max-width: 991px) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }
    }

    &__img {
        margin-top: rem(80);

        img {
            max-width: 100%;
            max-height: 100%;
        }

        @media (max-width: 991px) {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
            width: 160px;
            height: 160px;
            margin-top: 0;
        }

        @media (max-width: 575px) {
            position: absolute;
            width: 120px;
            height: 120px;
            right: 0;
            top: -20px;
        }
    }

    &__advantages {
        @media (max-width: 991px) {
            grid-column: 1 / 3;
            grid-row: 3 / 4;
            margin-top: rem(60);
            padding-left: rem(80);
        }

        li {
            padding: rem(40) 0;
            border-top: 1px solid $white-lime;
            position: relative;

            &:not(:last-child):before {
                content: '';
                position: absolute;
                left: -66px;
                height: 100%;
                width: 1px;
                top: 60px;
                background: $outlined-grey;

                @media (max-width: 991px) {
                    left: -54px;
                }
            }

            span {
                position: absolute;
                font-size: rem(22);
                line-height: 100%;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                border: 1px solid $outlined-grey;
                color: $white-lime;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 36px;
                left: -92px;
                background: url("../../../assets/images/general/bg-mini.png");

                @media (max-width: 991px) {
                    left: -80px;
                }
            }

            h3 {
                font-size: rem(44);
                line-height: 110%;
                color: $white-lime;
                margin-bottom: rem(22);
                font-weight: 500;
                text-transform: uppercase;

                @media (max-width: 575px) {
                    font-size: rem(32);
                }
            }

            p {
                font-size: rem(18);
                line-height: 130%;
                color: $grey-text;
                @media (max-width: 1199px) {
                    font-size: rem(16);
                }
            }
        }
    }
}