@import "../../../scss/variables";
@import "../../../scss/functions";

.reg_block {
    span {
        cursor: pointer;
        transition: all .3s ease-in-out;

        &.reg svg path {
            transition: all .3s ease-in-out;
        }

        &.reg:hover svg path {
            stroke: $green;
        }

        &.reg:hover {
            color: $green;
        }

        svg {
            margin-right: rem(10);
        }

        &:not(:first-child) {
            margin-left: rem(16);

            @media (max-width: 991px) {
                margin-top: rem(32);
            }
        }

        &.enter {
            border-radius: rem(40);
            padding: rem(9) rem(20);
            transition: all .3s ease-in-out;
            line-height: 100%;

            @media (max-width: 991px) {
                margin-left: 0;
            }
            & svg path {
                transition: all .3s ease-in-out;
            }

            &:hover {
                background: $green;
                color: #000;

                & svg path {
                    fill: #000;
                }
            }
        }
    }

    @media (max-width: 1199px) {
        margin: 0 rem(36) 0 auto;
    }

    @media (max-width: 991px) {
        margin: 0 0 rem(80) 0;
        flex-direction: column;
    }
}