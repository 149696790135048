@import 'variables';
@import 'functions.scss';

* {
    padding: 0;
    margin: 0;
    list-style: none;
    outline: none;
    font-family: 'TTSupermolotNeue', sans-serif;
    box-sizing: border-box;
}

a,
span,
div,
p,
b,
h1,
h2,
h3,
h4,
h5,
ul {
    color: $white-lime;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'TTSupermolotNeueCondensed', sans-serif;
}

body {
    background: url("../assets/images/general/bg.png");
}

h1 {
    font-size: rem(48);
}

h2 {
    font-weight: 600;
    font-size: rem(28);
    line-height: rem(30);
}

a {
    text-decoration: none;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.main {
    flex: 1;
    padding-top: rem(98);
    padding-bottom: rem(40);

    @media (max-width: 767px) {
        padding-top: rem(102);
    }
}

.page-item:not(:first-child) .page-link {
    margin-left: 0;
}

.no-scroll {
    overflow: hidden;
}


// container
.container {
    padding: 0 rem(20);
    @media (min-width: rem(575.98)) {
        max-width: rem(540);
        margin: 0 auto;
    }
    @media (min-width: rem(767.98)) {
        max-width: rem(728);
    }
    @media (min-width: rem(991.98)) {
        max-width: rem(960);
    }
    @media (min-width: rem(1199.98)) {
        max-width: rem(1280);
    }
}

// error class
.error {
    color: red;
    font-size: rem(12);
}

// success class
.success {
    color: $green;
    font-size: rem(12);
}

// disabled class
.disabled {
    opacity: 0.6;
    pointer-events: none;
}