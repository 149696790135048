@import "../../../scss/variables";
@import "../../../scss/functions";

.contactsWidget {
    position: fixed;
    z-index: 3;
    right: 50px;
    bottom: 50px;

    @media (max-width: 575px) {
        bottom: 20px;
        right: 20px;
    }

    &__btn {
        width: 80px;
        height: 80px;
        border: 1px solid #F8FFE4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #17181C;
        &:hover {
            border: 1px solid #7cff03;
        }
    }

    .list {
        padding: 20px;
        position: absolute;
        left: -205%;
        top: 0;
        transform: translateY(-120%);
        background: #17181C;
        border: 1px solid #F8FFE4;;
        border-radius: 15px;

        &::after {
            content: url("../../../assets/images/public/contacts_widget/triangle.png");
            position: absolute;
            right: 15px;
            bottom: -22px;
        }

        li {
            display: flex;
            align-items: center;
            gap: 10px;

            & + li {
                margin-top: rem(14);
            }

            &:hover .list__name {
                color: $green;
            }
        }

        &__link {
            display: flex;
            flex-direction: column;
        }

        &__title {
            font-size: rem(10);
            line-height: rem(11);
            color: $white-lime;
            opacity: 0.6;
        }

        &__name {
            transition: color .3s ease;
            font-size: rem(16);
            line-height: rem(18);
            color: $white-lime;
        }

    }


}
