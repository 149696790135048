@import "../../../scss/variables";
@import "../../../scss/functions";

.cards {
    &__item {
        display: flex;
        padding: rem(32);
        gap: 10px;
        border: 1px solid #F8FFE4;
        border-radius: rem(15);
        min-height: 300px;
        max-height: 450px;
        max-width: 880px;
        justify-content: space-between;

        @media (max-width: 1199px) {
            padding: rem(24);
            max-width: 100%;
        }

        @media (max-width: 767px) {
            padding: rem(16);
            flex-direction: column;
            min-height: 360px;
            max-height: 700px;
            gap: 20px;
        }

        @media (max-width: 380px) {
            max-height: 750px;
        }
    }
    &__left {
        width: 47%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    &__right {
        width: 47%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 767px) {
            width: 100%;
            gap: 20px;
            align-items: flex-end;
        }
    }
    
    &__description {
        color: #f8ffe4;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0%;
        text-align: left;
        opacity: 0.8;

    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        width: 100%;
        // margin-bottom: 1.5rem;
        background-color: #2C2D32;
        img {
            max-width: 160px;
            min-width: 100px;
            max-height: 100%;
        }
    }

    &__info {
        @media (max-width: 485px) {
            justify-content: center;
        }

        div {
            padding: rem(8) rem(13);
            background: $outlined-grey;
            border-radius: rem(54);

            img {
                margin-right: rem(6);
                width: rem(20);
            }

            span {
                font-size: rem(18);
                line-height: 100%;
                font-weight: 500;
                @media (max-width: 350px) {
                    font-size: rem(16);
                }
            }

            & + div {
                margin-left: rem(8);
            }
        }
    }

    &__stat {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 50px;
        padding: rem(16) 0;

        @media (max-width: 992px) {
            column-gap: 20px;
        }
        @media (max-width: 560px) {
            column-gap: 15px;
        }

        @media (max-width: 485px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 40px;
            grid-row-gap: 20px;
            justify-items: center;
        }
        @media (max-width: 380px) {
            grid-column-gap: 0px;
        }

        div {
            display: flex;
            flex-direction: column;
            width: 130px;
            @media (max-width: 560px) {
                width: 120px;
            }
            @media (max-width: 485px) {
                margin-right: 0px;
            }
    
        }

        &_name {
            color: #9FA496;
            font-size: rem(18);
            line-height: rem(21);
            font-weight: 500;
            margin-bottom: rem(4);
        }

        &_value {
            font-size: rem(24);
            line-height: rem(28);
            font-weight: 600;

            span {
                line-height: rem(21);
                text-transform: uppercase;
            }
        }
    }

    &__more {
        color: $green;
        font-size: rem(18);
        line-height: 100%;
        font-weight: 500;
        text-transform: uppercase;
        width: fit-content;
        margin-top: 10px;

        &:hover svg {
            transform: rotate(405deg);
        }

        svg {
            margin-left: rem(6);
            transition: transform .3s ease-out;
        }

        &:hover {
            color: $green;
        }
    }


}