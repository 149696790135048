@import '../../../scss/functions';
@import '../../../scss/variables';

.activeRegion:hover {
    cursor: pointer;
    // transition: all 0.3s ease;
}
.map_wrapper {
    overflow: scroll;
    text-align: center;
    &::-webkit-scrollbar {
        width: 0px;
        height: 1px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgb(53, 54, 59);
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(128, 255, 0);

      }
}