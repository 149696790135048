@import '../../../scss/functions';
@import '../../../scss/variables';

.offers__item {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    border: 1px solid $white-lime;
    padding: rem(32) rem(40);
    border-radius: 20px;

    @media (max-width: 1199px) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;
        padding: rem(32) rem(20);
    }

    .info {
        &__logo {
            height: rem(40);
            margin-bottom: rem(32);

            @media (max-width: 1200px) {
                margin-bottom: rem(20);
            }

            @media (max-width: 767px) {
                height: rem(32);
            }

            img {
                max-height: 100%;
            }
        }

        &__type {

            @media (max-width: 767px) {
                padding-bottom: rem(20);
                margin-bottom: rem(20);
                border-bottom: 1px solid $outlined-grey;
            }

            & > div {
                padding: rem(8) rem(13);
                border-radius: 54px;
                background: $outlined-grey;

                @media (max-width: 1199px) {
                    width: fit-content;
                }

                img {
                    width: 18px;
                    height: 18px;
                }

                & + div {
                    margin-left: rem(8);

                    @media (max-width: 1199px) {
                        margin-left: 0;
                        margin-top: rem(8);
                    }

                    @media (max-width: 767px) {
                        margin-left: rem(8);
                        margin-top: 0;
                    }
                }

                span {
                    font-weight: 500;
                    margin-left: rem(6);
                    line-height: 100%;
                }
            }
        }
    }

    .stats {
        @media (max-width: 767px) {
            margin-bottom: rem(20);
            padding-bottom: rem(20);
            border-bottom: 1px solid $outlined-grey;
        }
        &__name {
            font-size: rem(28);
            line-height: 100%;
            font-weight: 500;
            margin-bottom: rem(16);

            @media (max-width: 1199px) {
                font-size: rem(24);
            }
        }

        &__wrap {
            border-radius: 12px;
            position: relative;
            display: grid;
            overflow: hidden;

            :global .accordion-button {
                padding: rem(10);
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                position: static;

                @media (max-width: 1199px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 10px;
                }
            }

            :global .accordion-body {
                padding: 0 rem(10) rem(10) rem(10);
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;

                @media (max-width: 1199px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 10px;
                }
            }

            :global .accordion-button::after {
                content: none;
            }

            :global .accordion-button .accordion__arrow {
                position: absolute;
                background: url('../../../assets/images/general/arrow-down2.png') no-repeat;
                width: 24px;
                height: 24px;
                right: 10px;
                bottom: 10px;
                transition: all .3s ease;
            }

            :global .accordion-button:not(.collapsed) .accordion__arrow {
                transform: rotate(180deg);
            }


            .stats__item {
                @media (max-width: 1199px) {
                    display: flex;
                    align-items: center;
                }

                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .stats__title {
                    font-size: rem(18);
                    line-height: 100%;
                    font-weight: 500;
                    color: $grey-text;
                    margin-bottom: rem(4);

                    @media (max-width: 1199px) {
                        margin-bottom: 0;
                    }

                }

                .stats__nums {
                    font-size: rem(18);
                    line-height: 100%;
                    font-weight: 600;
                    color: $grey-text;
                    white-space: nowrap;

                    @media (max-width: 1199px) {
                        margin-left: rem(16);
                    }

                    @media (max-width: 767px) {
                        margin-left: 0;
                    }

                    span {
                        font-size: rem(24);
                        line-height: 100%;
                        font-weight: 600;
                        color: $white-lime;
                    }
                }
            }

        }
    }

    .chart {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: rem(106);

        @media (max-width: 1199px) and (min-width: 768px) {
            display: none;
        }

        @media (max-width: 767px) {
            max-height: rem(112);
            margin-bottom: rem(16);
        }
    }

    .average {
        &__wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 20px;
            padding-bottom: rem(10);
            border-bottom: 1px solid $outlined-grey;
            margin-bottom: rem(24);

            @media (max-width: 767px) {
                margin-bottom: rem(20);
            }
        }

        &__name {
            font-size: rem(18);
            line-height: rem(21);
            color: #999;
            font-weight: 500;
            margin-bottom: rem(4);
        }

        &__count {
            font-size: rem(24);
            line-height: rem(28);
            color: $white-lime;
            display: flex;
            align-items: center;

            svg {
                margin-left: rem(8);
            }
        }

        .connect {
            font-size: rem(18);
            line-height: 100%;
            font-weight: 500;
            color: $green;
            text-transform: uppercase;
            display: flex;
            align-items: flex-end;
            margin-left: auto;
            width: fit-content;

            @media (max-width: 767px) {
                margin-left: 0;
            }

            &:hover svg {
                transform: rotate(405deg);
            }

            svg {
                margin-left: rem(6);
                transition: transform 0.3s ease-out;
            }
        }
    }
}