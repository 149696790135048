@import "../../../scss/variables";
@import "../../../scss/functions";

.tnx {
    width: 50%;
    a:hover {
        color: #7CFF03;
    }
    @media (max-width: 992px) {
        width: auto;
    }
}
:global .custom-select__control {
    z-index: 3 !important;
}
:global .custom-select__single-value {
    color: #f8ffe4 !important;
}
:global .custom-select__menu {
    background: url('../../../assets/images/general/bg-mini.png');
    width: 100%;
    padding: 1.5625rem 0.5rem 0.5rem;
    border: 1px solid #4D4E53;
    border-top: none;
    border-radius: 0 0 12px 12px;
    top: 50% !important;
}

:global .custom-select__option.custom-select__option--is-selected,
:global .custom-select__option.custom-select__option--is-focused {
    background-color: transparent !important;
}

:global .custom-select__option {
    cursor: pointer !important;
    &:hover {
        background-color: transparent !important;
        color: #80FF00;
    }
}

.form {

    :global .form__input {
        background: url('../../../assets/images/general/bg-mini.png');
    }

    :global input, :global textarea, :global select {
        background: url('../../../assets/images/general/bg-mini.png');
    }

    .inputs__wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 700px;
        gap: 20px;
        margin-bottom: rem(24);

        @media (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            max-width: 100%;
        }
    }

    :global .textarea {
        grid-column: 1 / 3;
        resize: none;
        height: rem(100);

        @media (max-width: 767px) {
            grid-column: auto;
        }
    }

    :global .checkbox {
        grid-column: 1 / 3;
        width: fit-content;

        @media (max-width: 767px) {
            grid-column: auto;
        }

        label {
            display: flex;
            align-items: center;

            input {
                width: auto;
            }

            p {
                cursor: pointer;
                padding-left: rem(28);
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        max-width: 640px;
        gap: 20px;

        @media (max-width: 767px) {
            max-width: none;
            flex-direction: column;
        }

        :global button {
            width: 100%;

            @media (max-width: 767px) {
                margin-left: 0;
                width: 100%;
                justify-content: center;
            }
        }
    }

}