@import "./../variables";
@import "./../functions";

button {
    display: inline-block;
    border: none;
    background-size: 200% 100%;
    cursor: pointer;
    background: none;
    transition: all ease .3s;
    font-size: rem(18);
    line-height: 100%;
}

.outline-green {
    color: #000000;
    padding: rem(16) rem(32);
    background: linear-gradient(to bottom, $green 50%, #000 50%);
    background-size: 100% 200%;
    background-position: left top;
    border-radius: rem(35);
    box-shadow: 0 rem(9) rem(20) rgba(0, 0, 0, 0.4);
    font-weight: 500;
    font-size: rem(18);
    line-height: 100%;

    &:hover {
        background-position: right bottom;
        color: $green;
    }
}

.outline-grey {
    background: $outlined-grey;
}