@import "../../../scss/variables";
@import "../../../scss/functions";

.reviewsModal {
    width: rem(816);
    margin: auto;

    @media (max-width: 575px) {
        width: auto;
        padding: rem(20);
    }

    .imgBlock {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        box-shadow: 0 9px 22px rgba(0, 0, 0, 0.76);
        border: 2px solid $green;
        overflow: hidden;
        background: $white-lime;
        position: absolute;
        top: -69px;
        left: 60px;

        @media (max-width: 575px) {
            width: 80px;
            height: 80px;
            top: -44px;
            left: 10px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        padding: rem(100) rem(80);

        @media (max-width: 991px) {
            padding: rem(100) rem(40);
        }

        @media (max-width: 575px) {
            padding: rem(60) rem(20);
        }

        &__title {
            margin-bottom: rem(10);

            h2 {
                font-size: rem(44);
                line-height: 114%;
                font-weight: 500;
                text-transform: uppercase;

                @media (max-width: 575px) {
                    font-size: rem(28);
                }
            }

            a {
                display: flex;
                margin-left: rem(10);
                cursor: pointer;
                height: fit-content;

                svg {

                    &:hover rect {
                        fill: $green;
                    }

                    &:hover path {
                        fill: #000;
                    }
                }
            }
        }


        &__position {
            font-size: rem(16);
            line-height: 100%;
            font-weight: 400;
            color: $green;
            margin-bottom: rem(28);

            @media (max-width: 575px) {
                font-size: rem(14);
                margin-bottom: rem(12);
            }
        }

        &__text {
            color: $grey-text;
            font-size: rem(16);
            line-height: 142%;
            font-weight: 400;

            @media (max-width: 575px) {
                font-size: rem(14);
            }
        }
    }
}