@import '../../../scss/functions';
@import '../../../scss/variables';

.stats {
    &__cards {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(1, minmax(280px, 1fr));
        grid-auto-rows: auto;
        gap: rem(20);

        @media (max-width: 1199px) {
            grid-template-columns: repeat(1, minmax(280px, 1fr));
            width: 100%;
        }

    }

    &__img {
        width: 250px;
        height: 250px;
        @media (max-width: 460px) { 
            display: flex;
            width: auto;
            height: auto; 
        }
    }

    &__item {
        display: flex;
        gap: 30px;
        padding: rem(32);
        flex-direction: column;
        border: 1px solid #F8FFE4;
        border-radius: rem(15);
        justify-content: space-between;
        @media (max-width: 460px) { 
            padding: rem(24);
        }
        &:nth-child(2n) {
            .stats__content {
                flex-direction: column;
                gap: 40px;
                @media (max-width: 460px) { 
                    img {
                        width: auto;
                    }
                }
                // @media (max-width: 400px) { 
                //     img {
                //         width: 48px;
                //         height: 48px;
                //     }
                // }
            }
            .stats__img {
                width: auto;
                height: auto;
            }
            .stats__info {
                display: flex;
                width: 100%;
                gap: 0;
                justify-content: space-around;
                @media (max-width: 460px) { 
                    gap: 5px;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }
    }
    &__content {
        display: flex;
        gap: 100px;
        align-items: center;
        @media (max-width: 1299px) { 
            gap: 150px;
        }
        @media (max-width: 992px) { 
            gap: 50px;
        }
        @media (max-width: 460px) { 
            justify-content: space-evenly;
            gap: 20px;
            img {
                width: 155px;
            }
        }
    }

    &__title {
        font-size: rem(44);
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        line-height: 110%;
        @media (max-width: 1199px) {
            font-size: rem(36);
            overflow: visible;
        }
        @media (max-width: 767px) {
            font-size: rem(24);
            width: 70%;
        }
    }
    &__heading {
        display: flex;
        position: relative;
        overflow: hidden;
        gap: 5px;
        justify-content: space-between;
    }

    &__lines {
        display: block;
        &::after {
            content: url("../../../assets/images/general/lines-medium.svg");
            @media (max-width: 992px) { 
                right: -10px;
                content: url("../../../assets/images/general/lines-medium.svg");
            }
            @media (max-width: 767px) { 
                content: url("../../../assets/images/general/lines-two.svg");
            }
        }
        &.small {
            &::after {
                content: url("../../../assets/images/general/lines-small.svg");
                right: -21px;

                @media (max-width: 767px) { 
                    content: url("../../../assets/images/general/lines-two.svg");
                }
            }
            
        }
    }

    &__info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 100px;
        grid-row-gap: 32px;
        @media (max-width: 1199px) { 
            grid-column-gap: 60px;
        }
        @media (max-width: 767px) { 
            grid-column-gap: 60px;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 10px;
        }
        &_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 15px;
            &:nth-child(1) {
                border-left: 8px solid #80FF00;
            }
            &:nth-child(2) {
                border-left: 8px solid #66CC00;
            }
            &:nth-child(3) {
                border-left: 8px solid #4C9900;
                
            }
            &:nth-child(4) {
                border-left: 8px solid #336600;
            }
            @media (max-width: 460px) { 
                padding-left: 8px;
            }
        }
        &_name {
            color: rgb(159, 164, 150);
            font-size: rem(14);
            font-weight: 500;
            line-height: 14px;
            text-align: left;
            margin-bottom: 8px;
            @media (max-width: 767px) {
                font-size: rem(12);
                margin-bottom: 0;
            }
            // @media (max-width: 460px) {
            //     font-size: rem(10);
            // }
        }
        &_count {
            color: rgb(248, 255, 228);
            font-size: rem(24);
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            @media (max-width: 767px) {
                font-size: rem(18);
            }
            // @media (max-width: 460px) {
            //     font-size: rem(17);
            // }
        }
    }
}
.philippines {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
}
.vietnam {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__content {
            @media (max-width: 560px) {
                flex-direction: column;
            }
        }
        &__item {
            &:nth-child(2n) {
                .stats__info {
                    gap: 30px;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    @media (max-width: 560px) {
                        justify-content: space-between;
                    }
                    &_name { 
                        width: 150px;
                        @media (max-width: 560px) {
                            width: 100px;
                        }
                        // @media (max-width: 420px) {
                        //     width: 70px;
                        // }
                    }
                }
            }
            &:nth-child(2n+1) {
                .stats__info {
                    @media (max-width: 560px) {
                        display: flex;
                        width: 100%;
                        gap: 30px;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        &_name { 
                            width: 100px;
                        }
                    }
                    // @media (max-width: 420px) {
                    //     &_name { 
                    //         width: 70px;
                    //     }
                    // }
                }
            }
            &:nth-child(1) {
                .stats__info {
                    @media (max-width: 560px) {
                        flex-wrap: nowrap;
                        gap: 0px;
                    }
                    &_name { 
                        @media (max-width: 360px) {
                            width: 75px;
                        }
                    }

                }
            }
            &:nth-child(2) {
                .stats__info {
                    gap: 130px;
                    @media (max-width: 992px) { 
                        gap: 30px;
                    }
                    @media (max-width: 767px) {
                        gap: 0px;
                        flex-wrap: nowrap;
                    }
                }
            }
            &:nth-child(1),
            &:nth-child(2) {
                .stats__info {
                    &_item {
                        &:nth-child(1) {
                            border-left: 8px solid #80FF00;
                        }
                        &:nth-child(2) {
                            border-left: 8px solid #66CC00;
                        }
                        &:nth-child(3) {
                            border-left: 8px solid #336600;
                        }
                    }
                }
            }
        }
        &__info { 
            &_item {
                &:nth-child(1) {
                    border-left: 8px solid #80FF00;
                }
                &:nth-child(2) {
                    border-left: 8px solid #6FDE00;
                }
                &:nth-child(3) {
                    border-left: 8px solid #62C400;   
                }
                &:nth-child(4) {
                    border-left: 8px solid #4C9900;
                }
                &:nth-child(5) {
                    border-left: 8px solid #387000;
                }
                &:nth-child(6) {
                    border-left: 8px solid #274E00;
                }
                &:nth-child(7) {
                    border-left: 8px solid #182F00;
                }
            }
        }
    }
}
.united_states_stats {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__title {
            font-size: 44px;
            @media (max-width: 767px) { 
                font-size: 24px;
            }
        }
        &__item {
            @media (max-width: 460px) {
                padding: 1rem;
            }
        }
        &__info_us {
            display: flex;
            flex-direction: column;
            gap: 40px;
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_us_benefits {
            display: flex;
            width: 100%;
            gap: 70px;
            @media (max-width: 992px) {
                gap: 30px;
            }
            @media (max-width: 767px) {
                flex-direction: column;
            }
            &_title {
                color: #80FF00;
                font-size: 60px;
                font-weight: 500;
                text-transform: uppercase;
                margin-bottom: 12px;
                @media (max-width: 992px) {
                    font-size: 48px;
                }
                @media (max-width: 767px) {
                    margin-bottom: 0;
                }
                span {
                    font-size: 20px;
                    color: #80FF00;
                    @media (max-width: 992px) {
                        font-size: 20px;
                    }
                }
 
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_us_reasons {
            display: flex;
            width: 100%;
            gap: 40px;

            @media (max-width: 767px) {
                flex-direction: column;
            }
            &_item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 80px;
                width: 230px;
                padding-left: 12px;
                border-left: 8px solid #35363B;
            }
            &_count {
                color: #9FA496;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
            }
            &_text {
                color: #80FF00;
                font-size: 24px;
                font-weight: 500;
                line-height: 28px;
                text-transform: uppercase;
                @media (max-width: 992px) {
                    font-size: 20px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 80%;
            margin: 0 auto;
            row-gap: 50px;
            column-gap: 120px;
            @media (max-width: 767px) {
                width: 100%;
                row-gap: 25px;
                column-gap: 50px;
            }
            @media (max-width: 520px) {
                column-gap: 80px;
                justify-content: space-between; 
            }
            @media (max-width: 430px) {
                column-gap: 30px;
            }
            @media (max-width: 350px) {
                column-gap: 20px;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                @media (max-width: 400px) {
                    width: 62px;
                }
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
} 
.romania {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__content {
            display: block;
            width: 100%;
            @media (max-width: 460px) {
                img {
                    width: auto;
                }
            }
            @media (max-width: 425px) { 
                overflow: scroll;
                &::-webkit-scrollbar {
                    width: 0px;
                    height: 1px;
                  }
                  
                  /* Track */
                  &::-webkit-scrollbar-track {
                    background: rgb(53, 54, 59);
                  }
                   
                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: rgb(128, 255, 0);

                  }
                  
            }

        }
        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            align-items: center;
            &_img {
                @media (max-width: 425px) { 
                    width: 400px;
                    position: relative;
                    left: 20%;
                    display: block;
                    margin-bottom: 30px;
                }
                @media (max-width: 380px) { 
                    left: 40%;
                }
            }
            &_descr { 
                width: 100%;
                border-bottom: 1px solid rgb(128, 255, 0);
                border-radius: 8px 8px 0px 0px;
                background: rgb(35, 36, 41);
                padding: 32px;
                font-size: 24px;
                font-weight: 400;
                line-height: 32px;
                span {
                    color: #80FF00;
                }
                @media (max-width: 767px) {
                    font-size: 20px;
                    padding: 24px;
                }
                @media (max-width: 460px) { 
                    padding: 20px;
                }
            }
        }
        &__content_diagrams {
            display: flex;
            justify-content: space-around;
            align-items: center;
            @media (max-width: 767px) { 
                align-items: flex-start;
                flex-direction: column;
                gap: 30px;
            }
            .stats__block {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 40px;
                @media (max-width: 767px) { 
                    flex-direction: row;
                }
                @media (max-width: 460px) { 
                    gap: 25px;
                }
                .stats__info {
                    flex-direction: row;
                    align-items: flex-start;
                    @media (max-width: 767px) { 
                        flex-direction: column;
                        width: auto;
                        gap: 40px;
                        justify-content: inherit;
                    }
                    @media (max-width: 460px) { 
                        gap: 20px;  
                    }
                    
                    &_name {
                        margin-bottom: 15px;
                        font-size: 14px;
                        @media (max-width: 767px) { 
                            margin-bottom: 0px;
                        }
                        @media (max-width: 460px) { 
                            font-size: 12px;
                        }
                    }
                    &_count {
                        font-size: 24px;
                        @media (max-width: 460px) { 
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
.spain {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__content {
            svg {
                width: 300px;
                height: 286px;
            }
        }
        &__item {
            &:nth-child(3) {
                .stats__content {
                    gap: 40px;
                    flex-direction: row;
                    @media (max-width: 1280px) {
                        gap: 20px;
                    }
                    @media (max-width: 767px) {
                        flex-direction: column;
                    }
                }
            }
            &:nth-child(4) {
                .stats__content {
                    gap: 40px;
                    flex-direction: column;
                }
            }
        }
    }
}
.mexico {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__content {
            svg {
                width: 300px;
                height: 286px;
            }
            .region__map {
                display: flex;
                height: 150px;
            }
            .region__block {
                grid-template-rows: repeat(2, 1fr);
                .region__item__name {
                    width: 130px;
                    @media (max-width: 1250px) {
                        width: 110px;
                    }
                    @media (max-width: 767px) {
                        width: 100px;
                    } 
                    @media (max-width: 420px) {
                        width: 85px;
                    }
                }
            }
        }
        &__item {
            &:nth-child(3) {
                .stats__content {
                    gap: 0px;
                    flex-direction: row;
                    @media (max-width: 1280px) {
                        gap: 20px;
                    }
                    @media (max-width: 767px) {
                        flex-direction: column;
                        gap: 40px;
                    }
                }
            }
            &:nth-child(4) {
                .stats__content {
                    gap: 40px;
                    flex-direction: column;
                }
            }
        }
    }
}
.poland {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__item:nth-child(2) {
            margin-bottom: rem(20);
        }
        &__info_pl {
            display: flex;
            flex-direction: column;
            gap: 40px;
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 0px;
            column-gap: 0px;
            justify-content: space-around;

            @media (max-width: 520px) {
                justify-content: space-between; 
            }
            @media (max-width: 430px) {
                column-gap: 30px;
                row-gap: 30px;
            }
            @media (max-width: 350px) {
                column-gap: 20px;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.nigeria {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_ng {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 0px;
            column-gap: 0px;
            justify-content: space-around;

            @media (max-width: 520px) {
                justify-content: space-between; 
            }
            @media (max-width: 430px) {
                column-gap: 30px;
                row-gap: 30px;
            }
            @media (max-width: 350px) {
                column-gap: 20px;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.columbia {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_co {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
            }
            &_item {
                &:first-child {
                    grid-area: 1 / 1 / 2 / 3;
                }
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.czechia {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_cz {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-row-gap: 40px;
            grid-column-gap: 30px;
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
            }
            &_item {
                &:first-child {
                    grid-area: 1 / 1 / 2 / 3;
                }
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 30px;
            column-gap: 30px;
            justify-content: space-around;

            @media (max-width: 767px) {
                justify-content: flex-start;
                column-gap: 40px;
            }
            @media (max-width: 520px) {
                justify-content: space-around;
            }
            @media (max-width: 360px) {
                column-gap: 0px;
                justify-content: space-between;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
                @media (max-width: 520px) {
                    width: 35%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.india {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_in {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 60px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 45px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_city  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 40px;
            column-gap: 0px;
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                width: 20%;
                @media (max-width: 767px) {
                    width: 25%;
                }
                @media (max-width: 440px) {
                    width: 33%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
                img {
                    width: auto;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 40px;
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                width: 25%;
                @media (max-width: 440px) {
                    width: 33%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.kazakhstan {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
       &__item {
            &:first-child {
                .stats__content {
                    display: block;
                    width: 100%;
                    @media (max-width: 460px) {
                        img {
                            width: auto;
                        }
                    }
                    @media (max-width: 425px) { 
                        overflow: scroll;
                        &::-webkit-scrollbar {
                            width: 0px;
                            height: 1px;
                        }
                            
                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: rgb(53, 54, 59);
                        }
                            
                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: rgb(128, 255, 0);
                        }  
                    }
                }
                .stats__info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    &_img {
                        @media (max-width: 425px) { 
                            width: 400px;
                            position: relative;
                            left: 20%;
                            display: block;
                            margin-bottom: 30px;
                        }
                        @media (max-width: 380px) { 
                            left: 40%;
                        }
                    }
                    &_descr { 
                        width: 100%;
                        border-bottom: 1px solid rgb(128, 255, 0);
                        border-radius: 8px 8px 0px 0px;
                        background: rgb(35, 36, 41);
                        padding: 32px;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 32px;
                        span {
                            color: #80FF00;
                        }
                        @media (max-width: 767px) {
                            font-size: 20px;
                            padding: 24px;
                        }
                        @media (max-width: 460px) { 
                            padding: 20px;
                        }
                    }
                }
            }
       }
    }
}
.kenia {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_ke {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;
    
                @media (max-width: 1199px) {
                    font-size: 60px;
                }
    
                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;
    
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 40px;
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                width: 20%;
                @media (max-width: 440px) {
                    width: 33%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__item {
            &:nth-child(2n) {
                .stats__content {
                    flex-direction: row;
                    gap: 100px;
                    @media (max-width: 767px) {
                        gap: 40px;
                    }
                    @media (max-width: 520px) {
                        flex-direction: column;
                    }
                }
                .stats__img {
                    width: 413px;
                    height: auto;
                    @media (max-width: 520px) {
                        width: 200px;
                    }
                }
                .stats__info {
                    display: flex;
                    width: 100%;
                    gap: 20px;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }
        }
    }
}
.southAfrica {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_za {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;
    
                @media (max-width: 1199px) {
                    font-size: 60px;
                }
    
                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;
    
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 40px;
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                width: 20%;
                @media (max-width: 440px) {
                    width: 33%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.sriLanka {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_lk {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 30px;
            column-gap: 30px;
            justify-content: space-around;

            @media (max-width: 767px) {
                justify-content: flex-start;
                column-gap: 40px;
            }
            @media (max-width: 520px) {
                justify-content: space-around;
            }
            @media (max-width: 360px) {
                column-gap: 0px;
                justify-content: space-between;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
                @media (max-width: 520px) {
                    width: 35%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
    }
}
.ukraine {
    width: 75%;
    max-width: 880px;
    @media (max-width: 1199px) {
        width: 100%;
        max-width: 100%;
    }
    .stats {
        &__info_ua {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-column-gap: 40px;
            grid-row-gap: 40px;
            @media (max-width: 767px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-row-gap: 32px;
            }
            &_title {
                color: #80FF00;
                font-size: 80px;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0%;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 12px;

                @media (max-width: 1199px) {
                    font-size: 60px;
                }

                @media (max-width: 767px) {
                    font-size: 48px;
                }
            }
            &_text {
                color: #CCD1BD;
                font-size: 18px;
                font-weight: 400;
                line-height: 140%;
                letter-spacing: 0%;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
        }
        &__info_traffic  {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
            row-gap: 30px;
            column-gap: 30px;
            justify-content: space-around;

            @media (max-width: 767px) {
                justify-content: flex-start;
                column-gap: 40px;
            }
            @media (max-width: 520px) {
                justify-content: space-around;
            }
            @media (max-width: 360px) {
                column-gap: 0px;
                justify-content: space-between;
            }
            &_item {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                img {
                    width: auto;
                    @media (max-width: 400px) {
                        width: 48px;
                        height: 48px;
                    }
                }
                @media (max-width: 520px) {
                    width: 35%;
                }
                @media (max-width: 360px) {
                    width: 50%;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 18px;
                color: #CCD1BD;
                @media (max-width: 1199px) {
                    font-size: 16px;
                }
            }
            &_descr {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #9FA496;
            }
        }
    }
}

.region__block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    width: 100%;
    @media (max-width: 1280px) {
        gap: 20px;
    }
    @media (max-width: 992px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(3, 1fr);
    } 
    @media (max-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
    }
    .region__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 15px;
        border-left: 8px solid #35363B;
        background: transparent;
        cursor: pointer;
        &__name {
            width: 150px;
            color: rgb(159, 164, 150);
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            text-align: left;
            margin-bottom: 8px;
            @media (max-width: 767px) {
                width: 100px;
                font-size: 12px;
            } 
            @media (max-width: 420px) {
                width: 85px;
            }
        }
        &__count {
            color: #F8FFE4;
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 18px;
            } 
        }
    }
    .region__item.hovered-region {
        background: rgba(128, 255, 0, 0.1);
        border-left: 8px solid #80FF00;
        .region__item__name {
            color: #F8FFE4;
        }
    }
}
.chart {
    width: 100%;
    height: 100%;
    overflow: hidden; 
    @media (max-width: 767px) {
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 0px;
            height: 1px;
          }
          
          /* Track */
          &::-webkit-scrollbar-track {
            background: rgb(53, 54, 59);
          }
           
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgb(128, 255, 0);

          } 
    }
}
.chart-container {
    @media (max-width: 767px) {
        width: 800px;
    }
}

