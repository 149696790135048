@font-face {
  font-family: 'TTSupermolotNeue';
  src: url('./fonts/TTSupermolotNeue-Regular.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeue';
  src: url('./fonts/TTSupermolotNeue-Medium.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeue';
  src: url('./fonts/TTSupermolotNeue-DemiBold.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeue';
  src: url('./fonts/TTSupermolotNeue-Bold.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeueCondensed';
  src: url('./fonts/TTSupermolotNeue-CondRegular.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-CondRegular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeueCondensed';
  src: url('./fonts/TTSupermolotNeue-CondMed.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-CondMed.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeueCondensed';
  src: url('./fonts/TTSupermolotNeue-CondDBold.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-CondDBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TTSupermolotNeueCondensed';
  src: url('./fonts/TTSupermolotNeue-CondBold.woff') format('woff');
  src: url('./fonts/TTSupermolotNeue-CondBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}