@import '../variables';
@import '../functions.scss';

// global input styles
.form__input {
    width: 100%;

    label {
        width: 100%;
        height: inherit;
        position: relative;
    }

    input, select, textarea {
        width: 100%;
        border: 1px solid $white-lime;
        border-radius: 12px;
        font-size: rem(16);
        line-height: 100%;
        padding: rem(14) rem(20);
        color: $white-lime;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    textarea {
        resize: none;
        height: 100%;
    }

    select {
        -webkit-appearance: none;
        appearance: none;
        background: url("../../assets/images/general/arrow-down.svg") right 16px center no-repeat;
    }

    .checkbox__label {
        font-size: rem(15);
        line-height: 150%;
        font-weight: 400;

        a {
            color: $green;
        }
    }
}
[data-t="registration"] {
    .form__input {
        &:nth-child(3) {
            margin-bottom: 20px;
        }
    }
}


// custom checkbox input
.checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid $white-lime;
    border-radius: 4px;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox .checkmark:after {
    content: url("../../assets/images/general/done.svg");
    left: 1px;
    top: -2px;
    width: 17px;
    height: 17px;
}