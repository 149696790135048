@import "../../../scss/variables";
@import "../../../scss/functions";


.modal {
    :global .modal-dialog {
        min-width: 760px;
        max-width: 760px;

        .select__reg select option{
            background-color: $dark-grey;
            padding: 5px 0;
        }
        // .optional__input {
        //     margin-top: 20px;
        // }

        .input_req {
            position: relative;

            &:before {
                content: "*";
                position: absolute;
                left: rem(-14);
                top: rem(20);
                color: $red;
                font-size: rem(22);
                line-height: rem(22);
            }
        }
        @media (max-width: 767px) {
            max-width: var(--bs-modal-width);
            min-width: auto;
        }

        &[data-t="registration"] {
            min-width: 600px;
            max-width: 600px;

            @media (max-width: 767px) {
                max-width: var(--bs-modal-width);
                min-width: auto;
            }

            .modal-content {
                padding: rem(60) rem(40);

                @media (max-width: 767px) {
                    padding: rem(60) rem(32);
                }
            }

            .rules__form {
                color: $grey-checkbox;
                font-size: rem(15);
                text-align: center;
                margin-top: rem(20);
                a {
                    color: $green;
                }
            }
        }

        .google-autorize {
            display: flex;
            justify-content: center;
            margin-top: rem(20);

            .google-btn {
                border-radius: 24px;
                background: linear-gradient(180deg,#1a1b20 50%,#7cff03 0);
                background-position: 0 0;
                background-size: 100% 200%;
                border: 1px solid #7cff03;
                padding: 11px 32px;
                color: #F8FFE4;
                font-family: Supermolot, sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 16px */
                position: relative;
                width: 320px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .3s ease;
                &:hover {
                    background-position: 100% 100%;
                    color: #1a1b20;
                }
            }

            .google-btn:before {
                content: "";
                background: url("../../../assets/images/public/reg_log_modal/google.svg") no-repeat;
                width: 24px;
                height: 24px;
                display: block;
                margin-right: 12px;
            }
        }
    }

    :global .modal-content {
        background: url("../../../assets/images/public/reg_log_modal/lines.svg") no-repeat top right, $dark-grey;
        padding: rem(80);

        @media (max-width: 991px) {
            padding: rem(60);
        }

        @media (max-width: 767px) {
            padding: rem(40) rem(20);
        }

    }

    // bg for blocked account modal
    :global .blockedAccount {
        :global .modal-content {
            background: url("../../../assets/images/public/reg_log_modal/lines_blocked_account.svg") no-repeat top right, $dark-grey;
        }
    }

    .modal__wrapper {

        // general styles for all reg-log modals
        .form {
            .inputs__wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                margin-bottom: rem(24);

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 16px;
                }
            }


            :global input, :global textarea, :global .select {
                background: $dark-grey;
            }

            :global .checkbox {
                grid-column: 1 / 3;
                width: fit-content;

                @media (max-width: 767px) {
                    grid-column: auto;
                }

                label {
                    display: flex;
                    align-items: center;

                    input {
                        width: auto;
                    }

                    p {
                        cursor: pointer;
                        padding-left: rem(28);
                    }
                }
            }

            .form__footer {
                display: flex;
                align-items: center;
                gap: 20px;

                @media (max-width: 767px) {
                    flex-direction: column;
                }

                :global .button {
                    width: 100%;
                }
            }

        }

        // for login modal
        &.login {
            max-width: 325px;

            .modal__text {

                span {
                    @media (max-width: 767px) {

                    }
                }
            }

            .form {
                .inputs__wrapper {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                }

                :global .form__input {
                    grid-column: 1/3;
                }

                :global .checkbox {
                    grid-column: 1/2;

                    :global .checkbox__label {
                        color: $grey-text;
                        font-size: rem(16);
                    }
                }

                .forgot__password {
                    cursor: pointer;
                    grid-column: 2/3;
                    text-decoration: underline;
                    color: $grey-text;
                }

                &__footer {
                    display: flex;
                    flex-direction: column;

                    :global .button {
                        width: 100%;
                    }
                }

            }
        }

        // for blocked account modal
        &.blocked__account {
            max-width: 320px;

            .modal__title {
                font-size: rem(48);
                margin-bottom: rem(40);
                
                @media (max-width: 767px) {
                    font-size: rem(36);
                }
            }

            .modal__text {
                font-size: rem(24);
                font-weight: 700;
                line-height: 110%;
                color: $white-lime;
                font-family: 'TTSupermolotNeueCondensed', sans-serif;
                text-transform: uppercase;
                margin-bottom: rem(40);
            }

            .modal__info {
                font-size: rem(20);
                font-weight: 500;
                line-height: rem(24);
                font-family: 'TTSupermolotNeueCondensed', sans-serif;
                margin-bottom: rem(16);
            }

            .list {
                &__item {
                    display: flex;
                    gap: 8px;

                    span {
                        color: $grey-text;
                    }
                }

                &__item:not(:first-child) {
                    margin-top: rem(16);
                }

                a {
                    transition: color .3s ease;

                    &:hover {
                        color: $green;
                    }
                }
            }

        }

        // for forgot password modal
        &.forgot__password {
            max-width: rem(384);

            .modal__title {
                margin-bottom: rem(24);
            }


            .inputs__wrapper {
                grid-template-columns: repeat(1, 1fr)
            }

            .form__body {
                margin-bottom: rem(24);

                svg {
                    margin-bottom: rem(10);
                }

                p {
                    font-size: rem(18);
                    line-height: 120%;
                    font-weight: 400;
                    color: $grey-text;
                }
            }

            .form__footer {
                flex-direction: column;
                gap: 0;

                :global .button {
                    width: 100%;
                    margin-bottom: rem(24);
                }

                .forgot__password {
                    font-family: 'TTSupermolotNeueCondensed', sans-serif;
                    font-size: rem(20);
                    line-height: rem(24);
                    font-weight: 500;
                    color: $grey-text;
                    text-align: center;

                    @media (max-width: 767px) {
                        text-align: left;
                    }

                    span {
                        font-family: inherit;
                        margin-left: rem(6);
                        cursor: pointer;
                        color: $green;
                    }
                }
            }
        }

    }

    .modal__title {
        font-weight: 700;
        font-size: rem(56);
        line-height: 110%;
        text-transform: uppercase;
        margin-bottom: rem(8);

        @media (max-width: 991px) {
            font-size: rem(48);
            line-height: 114%;
        }

        @media (max-width: 767px) {
            font-size: rem(36);
            line-height: 110%;
        }
    }

    .modal__text {
        font-family: 'TTSupermolotNeueCondensed', sans-serif;
        font-size: rem(20);
        line-height: rem(24);
        font-weight: 500;
        color: rgba(248, 255, 228, 0.8);
        margin-bottom: rem(24);
        display: inline-grid;
        grid-template-columns: auto auto;
        gap: 6px;

        @media (max-width: 767px) {
            grid-template-columns: auto;
        }

        p {
            width: fit-content;
            font-family: 'TTSupermolotNeueCondensed', sans-serif;
        }

        span {
            font-family: 'TTSupermolotNeueCondensed', sans-serif;
            font-size: rem(20);
            line-height: rem(24);
            color: $green;
            cursor: pointer;
        }
    }

    [data-t="registration"] {
        .modal__title {
            font-size: rem(40);
            text-align: center;

            @media (max-width: 767px) {
                font-size: rem(24);
            }
        }
        .modal__text {
            width: 100%;
            justify-content: center;
            margin-top: rem(24);
            @media (max-width: 767px) {
                grid-template-columns: auto auto;
            }
        }

        .form {
            max-width: 320px;
            margin: 0 auto;

            .inputs__wrapper {
                display: flex;
                flex-direction: column;
            }

            &__footer {
                flex-direction: column;
            }
        }
    }

}

:global .select-reg__control {
    z-index: 3 !important;
}
:global .select-reg__menu {
    background: #2E2D32 !important;
    width: 100%;
    padding: 1.5625rem 0.5rem 0.5rem;
    border: 1px solid #4D4E53;
    border-top: none;
    box-shadow: none !important;
    border-radius: 0 0 12px 12px;
    top: 50% !important;
}
:global .select-reg__single-value {
    color: #f8ffe4 !important;
}
:global .select-reg__option.select-reg__option--is-selected,
:global .select-reg__option.select-reg__option--is-focused {
    background-color: transparent !important;
}

:global .select-reg__option {
    cursor: pointer !important;
    &:hover {
        background-color: transparent !important;
        color: #80FF00;
    }
}