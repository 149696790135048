@import "../../../scss/variables";
@import "../../../scss/functions";


.header {
    .menu {
        @media (max-width: 1199px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: rem(80);
            margin-top: rem(-40);
        }

        .link {
            margin-left: rem(50);
            transition: color .3s ease-in-out;

            &.active {
                color: $green;
            }

            @media (max-width: 1199px) {
                margin-left: 0;
                margin-bottom: rem(32);
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .link:hover {
            color: $green;
        }
    }



    .lang_block {
        margin-left: rem(36);

        @media (max-width: 1199px) {
            margin-left: 0;
        }

        span {
            cursor: pointer;
            opacity: 50%;

            &.active {
                opacity: 100%;
            }

            &:hover {
                opacity: 100%;
            }

            &:not(:first-child) {
                margin-left: rem(16);
            }

        }
    }

    :global .navbar {
        padding: rem(30) 0;
        font-size: rem(18);
        background: url('../../../assets/images/general/bg-mini.png');

        &.scrolled {
            box-shadow: 0 9px 20px rgba(0, 0, 0, 0.4);
        }

        .wrapper {
            @media (max-width: 1199px) {
                height: 100vh;
                flex-direction: column;
            }
        }

        &-toggler:not(.collapsed) {
            .header__burger {
                span {
                    display: none;
                }

                &::before,
                &::after {
                    width: 13px;
                    left: 6px;
                }

                &::before {
                    transform: rotate(45deg);
                    top: 6px;
                }

                &:after {
                    transform: rotate(-45deg);
                    bottom: 6px;
                }
            }
        }

        .header__burger {
            position: relative;
            width: 26px;
            height: 13px;

            span {
                display: block;
                position: absolute;
                top: 6px;
                width: 26px;
                height: 1px;
                background: $white-lime;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 26px;
                height: 1px;
                background: $white-lime;
                left: 0;
            }

            &::before {
                top: 0;
            }

            &::after {
                bottom: 0;
            }
        }
    }

    :global .navbar-toggler:focus {
        box-shadow: none;
    }

    :global .position-relative {
        padding-top: 55px;
        z-index: -1
    }
}

:global .dropdown-toggle.nav-link {
    margin-left: 10px;
}
:global .dropdown-menu.show {
    background: transparent;
    border: none;
    box-shadow: none;
    width: 65px;
    min-width: 100%;
    box-shadow: 0 9px 20px rgba(0, 0, 0, 0.4);
    background: url('../../../assets/images/general/bg-mini.png');
    border-radius: 10px;
}
:global .dropdown-item {
    color: #F8FFE4;
    opacity: 50%;
    font-size: 18px;
}
:global .dropdown-item.active, :global .dropdown-item:active {
    background-color: transparent;
}
:global .dropdown-item:focus, :global .dropdown-item:hover {
    background-color: transparent;
    color: #F8FFE4;
    opacity: 100%;
}