@import '../../../scss/functions';
@import '../../../scss/variables';

.pagination {
    :global .list {
        display: flex;
        gap: 10px;

        @media (max-width: 575px) {
            gap: 8px;
        }

        .page-item {
            &.disabled {
                opacity: 0.5;
            }

            &.active {
                color: $green;
                border: 1px solid $green;
            }

            &:last-child,
            &:first-child {
                border-radius: 50%;
            }

            &:first-child {
                margin-right: 10px;

                @media (max-width: 575px) {
                    margin-right: 4px;
                }
            }

            &:last-child {
                margin-left: 10px;

                @media (max-width: 575px) {
                    margin-left: 4px;
                }
            }

            min-width: 40px;
            min-height: 40px;
            border: 1px solid $white-lime;
            border-radius: 8px;

            @media (max-width: 575px) {
                min-width: 28px;
                min-height: 28px;
            }

            .page-link {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-size: 24px;
                line-height: 28px;

                @media (max-width: 575px) {
                    font-size: rem(16);
                    line-height: rem(18);

                    img {
                        width: 16px;
                    }
                }
            }

        }


    }
}