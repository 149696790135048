@import '../../../scss/functions';
@import '../../../scss/variables';

.top10Offer__card_wrapper {
    @media (max-width: 767px) {
        border-top: 1px solid $white-lime;

        &:last-child {
            border-bottom: 1px solid $white-lime;
        }
    }
}

.top10Offer__card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: rem(32) rem(40);
    border: 1px solid $white-lime;
    border-radius: 20px;
    gap: 40px;


    @media (max-width: 991px) {
        gap: 20px;
        padding: rem(32);
    }

    @media (max-width: 767px) {
        gap: 16px;
        padding: rem(20) rem(12);
        border: none;
        grid-template-columns: repeat(1, 1fr);
    }

    .top10Offer__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;

        @media (max-width: 991px) {
            gap: 20px;
        }

        @media (max-width: 767px) {
            flex-direction: row;
            align-items: center;

            .more {
                align-self: center;
            }
        }
    }

    .top10Offer__logo {
        height: rem(40);

        @media (max-width: 991px) {
            height: rem(32);
        }

        @media (max-width: 767px) {
            height: rem(30);
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .top10Offer__type {
        display: flex;
        gap: 8px;
        font-size: rem(16);
        line-height: 100%;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        @media (max-width: 767px) {
            display: none;
        }

        img {
            width: 18px;
            height: 18px;
        }

        & > div {
            gap: 6px;
            padding: rem(8) rem(16);
            background: $outlined-grey;
            border-radius: 34px;
            width: fit-content;
        }
    }

    .top10Offer__stats {
        display: flex;
        flex-direction: column;
        gap: 12px;
        justify-content: space-between;

        .top10Offer__name {
            font-size: rem(28);
            line-height: 100%;
            color: $white-lime;
            font-weight: 500;

            @media (max-width: 991px) {
                font-size: rem(24);
            }

            @media (max-width: 767px) {
                display: none;
            }
        }

        .top10Offer__stat {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            border: 1px solid $white-lime;
            border-radius: 12px;
            padding: 10px;
            min-height: rem(70);

            @media (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
            }

            @media (max-width: 767px) {
                grid-template-columns: repeat(2, 1fr);
                border: 1px solid $outlined-grey;
            }

            .top10Offer__stat__title {
                font-size: rem(18);
                font-weight: 500;
                line-height: 100%;
                color: $grey-text;
            }

            & > div {
                display: flex;
                flex-direction: column;
                gap: 4px;
                justify-content: space-between;

                @media (max-width: 991px) {
                    flex-direction: row;
                    align-items: center;
                }

                @media (max-width: 767px) {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

            .top10Offer__stat__count {
                font-size: rem(18);
                font-weight: 600;
                color: $grey-text;
                line-height: 100%;

                span {
                    font-size: rem(24);
                    color: $white-lime;
                }
            }
        }
    }

    .top10Offer__indicators {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > div {
            border-bottom: 1px solid $outlined-grey;
            padding-bottom: rem(10);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            @media (max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 16px;
            }

            @media (max-width: 767px) {
                border: none;
                padding: 0;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }
        }

        .top10Offer__indicator {
            display: flex;
            flex-direction: column;
            gap: 4px;

            @media (max-width: 991px) {
                display: grid;
                grid-template-columns: 48px 1fr;
                flex-direction: row;
                gap: 12px;
                align-items: center;
            }

            .top10Offer__indicator__name {
                font-size: rem(18);
                font-weight: 500;
                color: #999;
                line-height: rem(21);
            }

            .top10Offer__indicator__count {
                font-size: rem(24);
                line-height: rem(28);
                color: $white-lime;
                font-weight: 600;

                svg {
                    margin-left: rem(8);
                }
            }
        }


    }
    .more {
        align-items: flex-end;
        align-self: flex-end;
        color: $green;
        font-size: 18px;
        line-height: 100%;
        font-weight: 500;
        text-transform: uppercase;

        svg {
            margin-left: 6px;
            transition: transform .3s ease-out;
        }

        &:hover svg {
            transform: rotate(405deg);
        }
    }
}