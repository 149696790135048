@import "../../../scss/variables";
@import "../../../scss/functions";

.advantages {
    margin-bottom: rem(106);
    @media (max-width: 1199px) {
        margin-bottom: rem(80);
    }

    &__header--block {
        margin-bottom: rem(60);

        @media (max-width: 1199px) {
            margin-bottom: rem(30);
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(40) 0;
        border-top: 1px solid $white-lime;

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__info {
        h2 {
            font-size: rem(72);
            line-height: 110%;
            color: $white-lime;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: rem(14);

            @media (max-width: 1199px) {
                font-size: rem(36);
                line-height: 96.5%;
            }
        }

        p {
            font-size: rem(18);
            line-height: 140%;
            color: $grey-text;
            font-weight: 400;

            @media (max-width: 1199px) {
                font-size: rem(16);
            }
        }
    }

    &__img {
        min-width: rem(524);
        margin-right: rem(60);
        height: 200px;

        @media (max-width: 1199px) {
            margin-right: rem(30);
            min-width: rem(329);
        }

        @media (max-width: 767px) {
            max-height: rem(120);
            margin-bottom: rem(30);
        }
    }
}

:global .img__1 {
    background: url("../../../assets/images/public/advantages/adv-1.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages/adv-tablet-1.png") no-repeat left center;
    }
}

:global .img__2 {
    background: url("../../../assets/images/public/advantages/adv-2.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages/adv-tablet-2.png") no-repeat left center;
    }
}

:global .img__3 {
    background: url("../../../assets/images/public/advantages/adv-3.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages/adv-tablet-3.png") no-repeat left center;
    }
}

:global .img__4 {
    background: url("../../../assets/images/public/advantages/adv-4.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages/adv-tablet-4.png") no-repeat left center;
    }
}

:global .img__5 {
    background: url("../../../assets/images/public/advantages/adv-5.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages/adv-tablet-5.png") no-repeat left center;
    }
}