@import "../../../scss/variables";
@import "../../../scss/functions";

.advantages {
    margin-bottom: rem(106);
    @media (max-width: 1199px) {
        margin-bottom: rem(80);
    }

    &__header--block {
        margin-bottom: rem(60);

        @media (max-width: 1199px) {
            margin-bottom: rem(30);
        }
    }


    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(40);
        border: 1px solid $white-lime;
        border-radius: 20px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: all .3s ease-in-out;
        &:hover {
            border: 1px solid rgb(128, 255, 0);
            border-radius: 20px;
            background: rgb(35, 36, 41);
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__info {
        h2 {
            font-size: rem(64);
            line-height: 110%;
            color: $white-lime;
            text-transform: uppercase;
            font-weight: 500;
            margin-bottom: rem(14);

            @media (max-width: 1199px) {
                font-size: rem(36);
                line-height: 96.5%;
            }

            @media (max-width: 767px) {
                font-size: rem(32);
            }
        }

        p {
            font-size: rem(18);
            line-height: 140%;
            color: $grey-text;
            font-weight: 400;

            @media (max-width: 1199px) {
                font-size: rem(16);
            }
        }
    }

    &__img {
        min-width: rem(524);
        margin-right: rem(60);
        height: 205px;

        @media (max-width: 1199px) {
            margin-right: rem(30);
            min-width: rem(280);
            height: 130px;
        }

        @media (max-width: 767px) {
            max-height: rem(120);
            margin-bottom: rem(30);
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

:global .landing__img.img__1 {
    background: url("../../../assets/images/public/advantages_landing/adv-1.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages_landing/adv-tablet-1.png") no-repeat;
    }
}

:global .landing__img.img__2 {
    background: url("../../../assets/images/public/advantages_landing/adv-2.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages_landing/adv-tablet-2.png") no-repeat;
    }
}

:global .landing__img.img__3 {
    background: url("../../../assets/images/public/advantages_landing/adv-3.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages_landing/adv-tablet-3.png") no-repeat;
    }
}

:global .landing__img.img__4 {
    background: url("../../../assets/images/public/advantages_landing/adv-4.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages_landing/adv-tablet-4.png") no-repeat;
    }
}

:global .landing__img.img__5 {
    background: url("../../../assets/images/public/advantages_landing/adv-5.png") no-repeat;
    @media (max-width: 1199px) {
        background: url("../../../assets/images/public/advantages_landing/adv-tablet-5.png") no-repeat;
    }
}