@import "../../../scss/variables";
@import "../../../scss/functions";

:global .reviews {
    margin-bottom: rem(134);

    @media (max-width: 1199px) {
        margin-bottom: rem(74);
    }

    .slick-next:before,
    .slick-prev:before {
        content: '';
        width: 54px;
        height: 54px;
        display: block;

        @media (max-width: 1199px) {
            width: 34px;
            height: 34px;
        }
    }

    .slick-next:before {
        background: url("../../../assets/images/public/main_slider/arrow-right.svg");
        background-size: contain;
    }

    .slick-prev:before {
        background: url("../../../assets/images/public/main_slider/arrow-left.svg");
        background-size: contain;
    }

    .slick-next,
    .slick-prev {
        bottom: rem(-100);
        top: auto;
        width: rem(54);
        height: rem(54);
        
        @media (max-width: 1199px) {
            width: rem(34);
            height: rem(34);
            bottom: rem(-65);
        }
    }

    .slick-prev {
        left: auto;
        right: rem(70);

        @media (max-width: 1199px) {
            right: rem(45);
        }
    }

    .slick-next {
        right: rem(0);
    }

    .slick__reviews-1 {

        .slick-list {
            overflow: visible;

            @media (max-width: 767px) {
                overflow: hidden;
                padding: rem(20);
            }
        }

        .slick-slide {
            margin-right: rem(32);
            margin-bottom: rem(35);

            &:nth-child(5) {
                margin-right: 0;
                @media (max-width: 1199px) {
                    margin-right: rem(20);
                }

                @media (max-width: 767px) {
                    margin-right: 0;
                }
            }

            @media (max-width: 1199px) {
                margin-right: rem(20);
                margin-bottom: 0;
            }

            @media (max-width: 1199px) and (min-width: 767px) {
                width: 74px !important;
            }

            @media (max-width: 767px) {
                margin-right: 0;
            }


            &.slick-current div {
                border: 2px solid $green;
                transform: scale(1.4);

                img {
                    filter: none;

                }
            }

            & > div {
                width: rem(74);
                height: rem(74);
                border-radius: 50%;
                overflow: hidden;
                border: 2px solid #999999;
                background: $white-lime;
                cursor: pointer;
                transition: all .3s ease-out;

                @media (max-width: 767px) {
                    width: rem(62);
                    height: rem(62);
                }

            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
                -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
                -webkit-transition: all .6s ease; /* Fade to color for Chrome and Safari */
                -webkit-backface-visibility: hidden; /* Fix for transition flickering */
            }

            .slick-track {
                display: flex;
                flex-wrap: wrap;
            }

        }
    }

    .slick__reviews-2 {
        .slick-slide {
            padding: 0 1px;
        }
    }


    &__header_block {
        margin-bottom: rem(36);

        @media (max-width: 1199px) {
            margin-bottom: rem(45);
        }
    }


    &__row {
        min-width: rem(524);
        max-width: rem(524);
        margin-top: rem(35);
        margin-right: rem(60);

        @media (max-width: 1199px) {
            min-width: auto;
            max-width: none;
            margin: 0 0 rem(45) 0;
        }
    }

    &__card {
        button {
            &:hover {
                span {
                    color: $green;
                    border-bottom: 1px solid $green;
                }

                rect {
                    fill: $green;
                }
            }

            rect {
                transition: all .3s ease-out;
            }

            span {
                color: $white-lime;
                border-bottom: 1px solid $white-lime;
                transition: all .3s ease-out;
            }
        }

        svg {
            margin-right: rem(11);
        }
    }

    &__info {
        padding-top: rem(20);
        border-top: 1px solid rgba(248, 255, 228, 0.1);
        min-width: 0;

        @media (max-width: 1199px) {
            border: none;
            padding-top: 0;
        }
    }

    &__name {
        display: flex;
        font-size: rem(44);
        color: $white-lime;
        line-height: 114%;
        text-transform: uppercase;
        font-weight: 500;
        margin-bottom: rem(10);

        @media (max-width: 1199px) {
            font-size: rem(32);
        }

        @media (max-width: 767px) {
            font-size: rem(28);
        }

        a {
            display: flex;
            margin-left: rem(10);
            cursor: pointer;
            height: fit-content;

            svg {

                &:hover rect {
                    fill: $green;
                }

                &:hover path {
                    fill: #000;
                }
            }
        }


    }

    &__spec {
        color: $green;
        line-height: 100%;
        font-size: rem(16);
        margin-bottom: rem(28);

        @media (max-width: 1199px) {
            margin-bottom: rem(16);
        }

        @media (max-width: 767px) {
            font-size: rem(14);
        }
    }

    &__text {
        font-size: rem(16);
        line-height: 142%;
        color: $grey-text;
        font-weight: 400;
        margin-bottom: rem(17);
        white-space: pre-line;

        @media (max-width: 1199px) {
            margin-bottom: rem(20);
        }

        @media (max-width: 767px) {
            font-size: rem(14);
        }
    }
}

.main__title {
    min-width: rem(524);
    font-size: rem(72);
    line-height: 110%;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: rem(60);

    @media (max-width: 1199px) {
        font-size: rem(52);
        margin-bottom: rem(20);
        min-width: auto;
    }

    @media (max-width: 767px) {
        font-size: rem(36);
    }
}

.main__subtitle {
    font-size: rem(72);
    line-height: 110%;
    text-transform: uppercase;
    font-weight: 400;

    @media (max-width: 1199px) {
        font-size: rem(36);
    }
}