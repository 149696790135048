@import './../variables';
@import './../functions';

// faq accordion
.faq__wrapper .accordion-item {
    border: 1px solid $grey-text;
}

.accordion-item,
.accordion-item:first-of-type,
.accordion-item:last-of-type {
    border-radius: 12px;
    overflow: hidden;
}

.accordion-item,
.accordion-button {
    background: url('../../assets/images/general/bg-mini.png');
    font-family: "TTSupermolotNeue", sans-serif;
    color: $white-lime;
}

.accordion-button {
    padding: rem(16) rem(56) rem(16) rem(20);
}

.accordion-button:focus {
    border-color: $grey-text;
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: $white-lime;
    box-shadow: none;
}

.accordion-button::after {
    position: absolute;
    right: 20px;
    background: url("../../assets/images/general/arrow-down.svg") no-repeat;
    width: 16px;
    height: 16px;
}

.accordion-button:not(.collapsed)::after {
    background: url("../../assets/images/general/arrow-down.svg") no-repeat;
}